<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4 pt-5 pb-5">
            <h1 class="page-header">Summary</h1>
          </div>
          <div class="cell small-12 medium-7 medium-offset-1 pt-5 pb-5 text-right">
            <span v-if="patientTypes.includes('new')">
              <button v-if="selectedPatients === 'new'" class="button blue-active">New Patients</button>
              <button @click="selectedPatients = 'new'" v-else class="button blue-ghost">New Patients</button>
              &nbsp;
            </span>
            <span v-if="patientTypes.includes('switch')">
              <button v-if="selectedPatients === 'switch'" class="button blue-active">Switch Patients</button>
              <button @click="selectedPatients = 'switch'" v-else class="button blue-ghost">Switch Patients</button>
              &nbsp;
            </span>
            <span v-if="patientTypes.includes('switch') && patientTypes.includes('new')">
              <button v-if="selectedPatients === 'all'" class="button blue-active">All Patients</button>
              <button @click="selectedPatients = 'all'" v-else class="button blue-ghost">All Patients</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-10">
          <div class="cell small-12 medium-2">
            <h5 v-if="selectedPatients === 'new'" class="text-right">New Patients</h5>
            <h5 v-if="selectedPatients === 'switch'" class="text-right">Switch Patients<br />(per annum)</h5>
            <h5 v-if="selectedPatients === 'all'" class="text-right">All Patients</h5>
          </div>
          <div class="cell small-12 medium-2">
            <number v-number="thousands" v-if="selectedPatients === 'new'" type="text" class="text-center fixed" v-model="newPatients"></number>
            <number v-number="thousands" v-if="selectedPatients === 'switch'" type="text" class="text-center fixed" v-model="switchPatients"></number>
            <number v-number="thousands" v-if="selectedPatients === 'all'" type="text" class="text-center fixed" v-model="allPatients"></number>
          </div>
          <div class="cell small-12 medium-2">
            <h5 v-if="selectedPatients === 'switch'">Implementation Speed</h5>
          </div>
          <div class="cell small-12 medium-2">
            <input v-if="selectedPatients === 'switch'" type="text" disabled class="fixed" :value="`${implementationSpeed} months`">
          </div>
          <div class="cell small-12 medium-3 text-right">
            <span @click="viewType = 'table'" :class="{ active : viewType === 'table' }" class="tab-link">Table View</span>
            <span @click="viewType = 'graph'" :class="{ active: viewType === 'graph' }" class="tab-link">Graph View</span>
          </div>
        </div>
        <div class="pa-15">
          <div class="bg-mid-grey pa-10">
            <AllPatientsGraph v-if="selectedPatients === 'all' && viewType === 'graph'" />
            <AllPatientsTable v-if="selectedPatients === 'all' && viewType === 'table'" />
            <AllPatientsTotals v-if="selectedPatients === 'all' && viewType === 'totals'" />
            <NewPatientsGraph v-if="selectedPatients === 'new' && viewType === 'graph'" />
            <NewPatientsTable v-if="selectedPatients === 'new' && viewType === 'table'" />
            <NewPatientsTotals v-if="selectedPatients === 'new' && viewType === 'totals'" />
            <SwitchPatientsGraph v-if="selectedPatients === 'switch' && viewType === 'graph'" />
            <SwitchPatientsTable v-if="selectedPatients === 'switch' && viewType === 'table'" />
            <SwitchPatientsTotals v-if="selectedPatients === 'switch' && viewType === 'totals'" />
            <div class="grid-x grid-padding-x pt-2">
              <div class="cell small-12 medium-1">
                <span @click="viewType = 'table'" v-if="viewType === 'totals'">
                  <img width="30px" src="../assets/arrow_buttons/back_arrow_active.png" alt="Prev" />
                </span>
                <span v-if="viewType === 'table'">
                  <img width="30px" src="../assets/arrow_buttons/back_arrow_inactive.png" alt="Prev" />
                </span>
              </div>
              <div class="cell small-12 medium-1 medium-offset-10">
                <span @click="viewType = 'totals'" v-if="viewType === 'table'">
                  <img width="30px" src="../assets/arrow_buttons/forward_arrow_active.png" alt="Next" />
                </span>
                <span v-if="viewType === 'totals'">
                  <img width="30px" src="../assets/arrow_buttons/forward_arrow_inactive.png" alt="Next" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-x grid-padding-x pb-5">
          <div class="cell small-12 medium-2 medium-offset-7 relative">
            <EmailForm @close="emailForm = false" v-if="emailForm === true" />
            <button @click="emailForm = true" class="button expanded orange">Email report</button>
          </div>
          <div class="cell small-12 medium-2">
            <button @click="finish" class="button expanded orange">Finish</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import AllPatientsTotals from '@/components/Summary/AllPatientsTotals.vue';
import AllPatientsGraph from '@/components/Summary/AllPatientsGraph.vue';
import AllPatientsTable from '@/components/Summary/AllPatientsTable.vue';
import NewPatientsGraph from '@/components/Summary/NewPatientsGraph.vue';
import NewPatientsTable from '@/components/Summary/NewPatientsTable.vue';
import NewPatientsTotals from '@/components/Summary/NewPatientsTotals.vue';
import SwitchPatientsGraph from '@/components/Summary/SwitchPatientsGraph.vue';
import SwitchPatientsTable from '@/components/Summary/SwitchPatientsTable.vue';
import SwitchPatientsTotals from '@/components/Summary/SwitchPatientsTotals.vue';
import EmailForm from '@/components/Summary/EmailForm.vue';

export default {
  name: 'Summary',
  components: {
    Header,
    AllPatientsTotals,
    AllPatientsGraph,
    AllPatientsTable,
    NewPatientsGraph,
    NewPatientsTable,
    NewPatientsTotals,
    SwitchPatientsGraph,
    SwitchPatientsTable,
    SwitchPatientsTotals,
    EmailForm,
  },
  computed: {
    hash() { return this.$store.state.hash; },
    newPatients() {
      if (this.timeframe === 1) {
        return this.$store.getters.totalNewPatients;
      }
      return (this.$store.getters.totalNewPatients * 3);
    },
    switchPatients() { return this.$store.getters.totalSwitchPatients; },
    allPatients() { return this.$store.getters.totalAllPatients; },
    patientTypes() { return this.$store.state.patientTypes; },
    implementationSpeed() { return this.$store.state.speedOfImplementation; },
    timeframe() { return this.$store.state.timeframe; },
  },
  data() {
    return {
      selectedPatients: null,
      viewType: 'table',
      thousands: {
        masked: true,
        separator: ',',
      },
      emailForm: false,
    };
  },
  methods: {
    sendPage() {
      this.$store.commit('setPage', 'summary');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
    finish() {
      this.$store.commit('clear');
      this.$router.push('/start');
    },
    loadSelected() {
      if (this.patientTypes.includes('new')) {
        this.selectedPatients = 'new';
      } else {
        this.selectedPatients = 'switch';
      }
    },
  },
  mounted() {
    this.checkHashExists();
    this.sendPage();
    this.loadSelected();
  },
};
</script>
