<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3 medium-offset-3">
        <h5 class="text-center">Current Situation</h5>
      </div>
      <div class="cell small-12 medium-3">
        <h5 class="text-center">Future Scenario</h5>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Byooviz</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ byovizCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ byovizFuture | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="wide orange-line" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Lucentis</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ lucentisCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ lucentisFuture | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div v-if="drugs.includes('biosimilars 2')" class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="wide orange-line" />
      </div>
    </div>
    <div v-if="drugs.includes('biosimilars 2')" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">biosimilars 2</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ biosimilarCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ biosimilarFuture | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-9">
        <hr class="wide orange-line" />
      </div>
      <div class="cell small-3">
        <h5>Difference</h5>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Total</button>
      </div>
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-alt">
          &pound;{{ totalCurrent | numeralFormat('0,0[.]00') }}
        </button>
      </div>
      <div class="cell small-12 medium-3">
        <button class="button expanded">
          &pound;{{ totalFuture | numeralFormat('0,0[.]00') }}
        </button>
      </div>
      <div class="cell small-12 medium-3">
        <button class="button expanded grey">
          <i class="plain" v-if="differenceIsNegative">-</i>
          &pound;{{ displayDifference | numeralFormat('0,0[.]00') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchPatientsTotals',
  data() {
    return {
      differenceIsNegative: false,
      displayDifference: 0,
    };
  },
  computed: {
    drugs() { return this.$store.state.drugs; },
    timeframe() { return this.$store.state.timeframe; },
    byovizCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearOne + this.$store.getters.switchPatientsCurrentCostDmeByovizYearOne + this.$store.getters.switchPatientsCurrentCostPdrByovizYearOne + this.$store.getters.switchPatientsCurrentCostRvoByovizYearOne + this.$store.getters.switchPatientsCurrentCostCnvByovizYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearTwo + this.$store.getters.switchPatientsCurrentCostDmeByovizYearTwo + this.$store.getters.switchPatientsCurrentCostPdrByovizYearTwo + this.$store.getters.switchPatientsCurrentCostRvoByovizYearTwo + this.$store.getters.switchPatientsCurrentCostCnvByovizYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearThree + this.$store.getters.switchPatientsCurrentCostDmeByovizYearThree + this.$store.getters.switchPatientsCurrentCostPdrByovizYearThree + this.$store.getters.switchPatientsCurrentCostRvoByovizYearThree + this.$store.getters.switchPatientsCurrentCostCnvByovizYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOne + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOne + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOne + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOne + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearThree + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearThree + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearThree + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearThree + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalCurrent() {
      return (this.byovizCurrent + this.lucentisCurrent + this.biosimilarCurrent);
    },
    byovizFuture() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvByovizYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureByovizWamd
        + this.$store.getters.switchPatientsThreeYearFutureByovizDme
        + this.$store.getters.switchPatientsThreeYearFutureByovizPdr
        + this.$store.getters.switchPatientsThreeYearFutureByovizRvo
        + this.$store.getters.switchPatientsThreeYearFutureByovizCnv
      );
    },
    lucentisFuture() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureLucentisWamd
        + this.$store.getters.switchPatientsThreeYearFutureLucentisDme
        + this.$store.getters.switchPatientsThreeYearFutureLucentisPdr
        + this.$store.getters.switchPatientsThreeYearFutureLucentisRvo
        + this.$store.getters.switchPatientsThreeYearFutureLucentisCnv
      );
    },
    biosimilarFuture() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureBiosimilarWamd
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarDme
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarPdr
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarRvo
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarCnv
      );
    },
    totalFuture() {
      return (this.byovizFuture + this.lucentisFuture + this.biosimilarFuture);
    },
    difference() {
      const difference = (this.totalCurrent - this.totalFuture);
      return difference;
    },
  },
  methods: {
    isDifferenceNegative() {
      const diff = (this.totalCurrent - this.totalFuture);
      if (diff > 0) this.differenceIsNegative = true;
      this.displayDifference = Math.abs(this.difference);
    },
  },
  mounted() {
    this.isDifferenceNegative();
  },
};
</script>
