<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3 medium-offset-3">
        <h5 class="text-center">Current Situation</h5>
      </div>
      <div class="cell small-12 medium-3">
        <h5 class="text-center">Future Scenario</h5>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Byooviz</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ byovizCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span v-if="timeframe === 1" class="input-fixed text-center">&pound;{{ byovizFutureOneYear | numeralFormat('0,0[.]00') }}</span>
        <span v-else class="input-fixed text-center">&pound;{{ byovizFutureThreeYear | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="wide orange-line" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Lucentis</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ lucentisCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span v-if="timeframe === 1" class="input-fixed text-center">&pound;{{ lucentisFutureOneYear | numeralFormat('0,0[.]00') }}</span>
        <span v-else class="input-fixed text-center">&pound;{{ lucentisFutureThreeYear | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div v-if="drugs.includes('biosimilars 2')" class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="wide orange-line" />
      </div>
    </div>
    <div v-if="drugs.includes('biosimilars 2')" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">biosimilars 2</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ biosimilarCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span v-if="timeframe === 1" class="input-fixed text-center">&pound;{{ biosimilarFutureOneYear | numeralFormat('0,0[.]00') }}</span>
        <span v-else class="input-fixed text-center">&pound;{{ biosimilarFutureThreeYear | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-9">
        <hr class="wide orange-line" />
      </div>
      <div class="cell small-3">
        <h5>Difference</h5>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Total</button>
      </div>
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-alt">
          &pound;{{ totalCurrent | numeralFormat('0,0[.]00') }}
        </button>
      </div>
      <div class="cell small-12 medium-3">
        <button v-if="timeframe === 1" class="button expanded">
          &pound;{{ totalFutureOneYear | numeralFormat('0,0[.]00') }}
        </button>
        <button v-else class="button expanded">
          &pound;{{ totalFutureThreeYear | numeralFormat('0,0[.]00') }}
        </button>
      </div>
      <div class="cell small-12 medium-3">
        <button v-if="timeframe === 1" class="button expanded grey">
          <i class="plain" v-if="differenceIsNegative">-</i>
          &pound;{{ displayDifference | numeralFormat('0,0[.]00') }}
        </button>
        <button v-else class="button expanded grey">
          <i class="plain" v-if="differenceIsNegative">-</i>
          &pound;{{ displayDifference | numeralFormat('0,0[.]00') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewPatientsTotals',
  data() {
    return {
      differenceIsNegative: false,
      displayDifference: 0,
    };
  },
  computed: {
    timeframe() { return this.$store.state.timeframe; },
    drugs() { return this.$store.state.drugs; },
    byovizCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdByovizYearOne + this.$store.getters.newPatientsCurrentCostDmeByovizYearOne + this.$store.getters.newPatientsCurrentCostPdrByovizYearOne + this.$store.getters.newPatientsCurrentCostRvoByovizYearOne + this.$store.getters.newPatientsCurrentCostCnvByovizYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearTwo + this.$store.getters.newPatientsCurrentCostDmeByovizYearTwo + this.$store.getters.newPatientsCurrentCostPdrByovizYearTwo + this.$store.getters.newPatientsCurrentCostRvoByovizYearTwo + this.$store.getters.newPatientsCurrentCostCnvByovizYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearThree + this.$store.getters.newPatientsCurrentCostDmeByovizYearThree + this.$store.getters.newPatientsCurrentCostPdrByovizYearThree + this.$store.getters.newPatientsCurrentCostRvoByovizYearThree + this.$store.getters.newPatientsCurrentCostCnvByovizYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOne + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOne + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOne + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOne + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwo + this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwo + this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwo + this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwo + this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearThree + this.$store.getters.newPatientsCurrentCostDmeLucentisYearThree + this.$store.getters.newPatientsCurrentCostPdrLucentisYearThree + this.$store.getters.newPatientsCurrentCostRvoLucentisYearThree + this.$store.getters.newPatientsCurrentCostCnvLucentisYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalCurrent() {
      return (this.byovizCurrent + this.lucentisCurrent + this.biosimilarCurrent);
    },
    byovizFutureOneYear() {
      return (this.$store.getters.newPatientsCurrentCostWamdByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvByovizYearOneFuture);
    },
    byovizFutureThreeYear() {
      return (
        this.$store.getters.newPatientsWamdByovizThreeYearFuture
        + this.$store.getters.newPatientsDmeByovizThreeYearFuture
        + this.$store.getters.newPatientsPdrByovizThreeYearFuture
        + this.$store.getters.newPatientsRvoByovizThreeYearFuture
        + this.$store.getters.newPatientsCnvByovizThreeYearFuture);
    },
    lucentisFutureOneYear() {
      return (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOneFuture);
    },
    lucentisFutureThreeYear() {
      return (this.$store.getters.newPatientsWamdLucentisThreeYearFuture
        + this.$store.getters.newPatientsDmeLucentisThreeYearFuture
        + this.$store.getters.newPatientsPdrLucentisThreeYearFuture
        + this.$store.getters.newPatientsRvoLucentisThreeYearFuture
        + this.$store.getters.newPatientsCnvLucentisThreeYearFuture);
    },
    biosimilarFutureOneYear() {
      return (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOneFuture);
    },
    biosimilarFutureThreeYear() {
      return (this.$store.getters.newPatientsWamdBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsDmeBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsPdrBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsRvoBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsCnvBiosimilarThreeYearFuture);
    },
    totalFutureOneYear() {
      return (this.byovizFutureOneYear + this.lucentisFutureOneYear + this.biosimilarFutureOneYear);
    },
    totalFutureThreeYear() {
      return (this.byovizFutureThreeYear + this.lucentisFutureThreeYear + this.biosimilarFutureThreeYear);
    },
    totalDifferenceOneYear() {
      const difference = (this.totalCurrent - this.totalFutureOneYear);
      return difference;
    },
    totalDifferenceThreeYear() {
      const difference = (this.totalCurrent - this.totalFutureThreeYear);
      return difference;
    },
  },
  methods: {
    isDifferenceNegative() {
      let diff = 0;
      if (this.timeframe === 1) {
        diff = (this.totalCurrent - this.totalFutureOneYear);
        if (diff > 0) this.differenceIsNegative = true;
        this.displayDifference = Math.abs(this.totalDifferenceOneYear);
      } else {
        diff = (this.totalCurrent - this.totalFutureThreeYear);
        if (diff > 0) this.differenceIsNegative = true;
        this.displayDifference = Math.abs(this.totalDifferenceThreeYear);
      }
    },
  },
  mounted() {
    this.isDifferenceNegative();
  },
};
</script>
