<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 text-center">
        <h5>Current Situation</h5>
      </div>
      <div class="cell small-12 medium-6 text-center">
        <h5>Future Situation</h5>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="current-cell">wAMD</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">DME</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">PDR</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">RVO</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">CNV</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="future-cell">wAMD</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">DME</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">PDR</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">RVO</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">CNV</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('byoviz')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Byooviz</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ byovizWamdFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ byovizWamdFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ byovizDmeFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ byovizDmeFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ byovizPdrFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ byovizPdrFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ byovizRvoFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ byovizRvoFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ byovizCnvFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ byovizCnvFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('lucentis')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Lucentis</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ lucentisWamdFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ lucentisWamdFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ lucentisDmeFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ lucentisDmeFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ lucentisPdrFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ lucentisPdrFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ lucentisRvoFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ lucentisRvoFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ lucentisCnvFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ lucentisCnvFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('biosimilars 2')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">biosimilars 2</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ biosimilarWamdFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ biosimilarWamdFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ biosimilarDmeFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ biosimilarDmeFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ biosimilarPdrFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ biosimilarPdrFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ biosimilarRvoFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ biosimilarRvoFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ biosimilarCnvFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ biosimilarCnvFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Total</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ totalWamdFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ totalWamdFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ totalDmeFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ totalDmeFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ totalPdrFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ totalPdrFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ totalRvoFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ totalRvoFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span v-if="timeframe === 1" class="text-small">&pound;{{ totalCnvFutureOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="text-small">&pound;{{ totalCnvFutureThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-10">
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Total current situation:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">&pound;{{ totalCurrentSituation | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Total future siutuation:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span v-if="timeframe === 1" class="input-fixed text-center">&pound;{{ totalFutureSituationOneYear | numeralFormat('0,0[.]') }}</span>
        <span v-else class="input-fixed text-center">&pound;{{ totalFutureSituationThreeYear | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Difference:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">
          <i class="plain" v-if="differenceIsNegative">-</i>
          &pound;{{ displayDifference | numeralFormat('0,0[.]') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewPatientsTable',
  data() {
    return {
      differenceIsNegative: false,
      displayDifference: 0,
    };
  },
  computed: {
    drugs() {
      return this.$store.state.drugs;
    },
    timeframe() {
      return this.$store.state.timeframe;
    },
    lucentisWamd() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizWamd() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarWamd() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisDme() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizDme() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarDme() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisPdr() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizPdr() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarPdr() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisRvo() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizRvo() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarRvo() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCnv() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizCnv() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCnv() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalWamd() {
      return (this.lucentisWamd + this.byovizWamd + this.biosimilarWamd);
    },
    totalDme() {
      return (this.lucentisDme + this.byovizDme + this.biosimilarDme);
    },
    totalPdr() {
      return (this.lucentisPdr + this.byovizPdr + this.biosimilarPdr);
    },
    totalRvo() {
      return (this.lucentisRvo + this.byovizRvo + this.biosimilarRvo);
    },
    totalCnv() {
      return (this.lucentisCnv + this.byovizCnv + this.biosimilarCnv);
    },
    totalCurrentSituation() {
      return (this.totalWamd + this.totalDme + this.totalPdr + this.totalRvo + this.totalCnv);
    },
    lucentisWamdFutureOneYear() { return this.$store.getters.newPatientsCurrentCostWamdLucentisYearOneFuture; },
    lucentisWamdFutureThreeYear() { return this.$store.getters.newPatientsWamdLucentisThreeYearFuture; },
    byovizWamdFutureOneYear() { return this.$store.getters.newPatientsCurrentCostWamdByovizYearOneFuture; },
    byovizWamdFutureThreeYear() { return this.$store.getters.newPatientsWamdByovizThreeYearFuture; },
    biosimilarWamdFutureOneYear() { return this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOneFuture; },
    biosimilarWamdFutureThreeYear() { return this.$store.getters.newPatientsWamdBiosimilarThreeYearFuture; },
    lucentisDmeFutureOneYear() { return this.$store.getters.newPatientsCurrentCostDmeLucentisYearOneFuture; },
    lucentisDmeFutureThreeYear() { return this.$store.getters.newPatientsDmeLucentisThreeYearFuture; },
    byovizDmeFutureOneYear() { return this.$store.getters.newPatientsCurrentCostDmeByovizYearOneFuture; },
    byovizDmeFutureThreeYear() { return this.$store.getters.newPatientsDmeByovizThreeYearFuture; },
    biosimilarDmeFutureOneYear() { return this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOneFuture; },
    biosimilarDmeFutureThreeYear() { return this.$store.getters.newPatientsDmeBiosimilarThreeYearFuture; },
    lucentisPdrFutureOneYear() { return this.$store.getters.newPatientsCurrentCostPdrLucentisYearOneFuture; },
    lucentisPdrFutureThreeYear() { return this.$store.getters.newPatientsPdrLucentisThreeYearFuture; },
    byovizPdrFutureOneYear() { return this.$store.getters.newPatientsCurrentCostPdrByovizYearOneFuture; },
    byovizPdrFutureThreeYear() { return this.$store.getters.newPatientsPdrByovizThreeYearFuture; },
    biosimilarPdrFutureOneYear() { return this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOneFuture; },
    biosimilarPdrFutureThreeYear() { return this.$store.getters.newPatientsPdrBiosimilarThreeYearFuture; },
    lucentisRvoFutureOneYear() { return this.$store.getters.newPatientsCurrentCostRvoLucentisYearOneFuture; },
    lucentisRvoFutureThreeYear() { return this.$store.getters.newPatientsRvoLucentisThreeYearFuture; },
    byovizRvoFutureOneYear() { return this.$store.getters.newPatientsCurrentCostRvoByovizYearOneFuture; },
    byovizRvoFutureThreeYear() { return this.$store.getters.newPatientsRvoByovizThreeYearFuture; },
    biosimilarRvoFutureOneYear() { return this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOneFuture; },
    biosimilarRvoFutureThreeYear() { return this.$store.getters.newPatientsRvoBiosimilarThreeYearFuture; },
    lucentisCnvFutureOneYear() { return this.$store.getters.newPatientsCurrentCostCnvLucentisYearOneFuture; },
    lucentisCnvFutureThreeYear() { return this.$store.getters.newPatientsCnvLucentisThreeYearFuture; },
    byovizCnvFutureOneYear() { return this.$store.getters.newPatientsCurrentCostCnvByovizYearOneFuture; },
    byovizCnvFutureThreeYear() { return this.$store.getters.newPatientsCnvByovizThreeYearFuture; },
    biosimilarCnvFutureOneYear() { return this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOneFuture; },
    biosimilarCnvFutureThreeYear() { return this.$store.getters.newPatientsCnvBiosimilarThreeYearFuture; },
    totalWamdFutureOneYear() {
      return (this.lucentisWamdFutureOneYear + this.byovizWamdFutureOneYear + this.biosimilarWamdFutureOneYear);
    },
    totalWamdFutureThreeYear() {
      return (this.lucentisWamdFutureThreeYear + this.byovizWamdFutureThreeYear + this.biosimilarWamdFutureThreeYear);
    },
    totalDmeFutureOneYear() {
      return (this.lucentisDmeFutureOneYear + this.byovizDmeFutureOneYear + this.biosimilarDmeFutureOneYear);
    },
    totalDmeFutureThreeYear() {
      return (this.lucentisDmeFutureThreeYear + this.byovizDmeFutureThreeYear + this.biosimilarDmeFutureThreeYear);
    },
    totalPdrFutureOneYear() {
      return (this.lucentisPdrFutureOneYear + this.byovizPdrFutureOneYear + this.biosimilarPdrFutureOneYear);
    },
    totalPdrFutureThreeYear() {
      return (this.lucentisPdrFutureThreeYear + this.byovizPdrFutureThreeYear + this.biosimilarPdrFutureThreeYear);
    },
    totalRvoFutureOneYear() {
      return (this.lucentisRvoFutureOneYear + this.byovizRvoFutureOneYear + this.biosimilarRvoFutureOneYear);
    },
    totalRvoFutureThreeYear() {
      return (this.lucentisRvoFutureThreeYear + this.byovizRvoFutureThreeYear + this.biosimilarRvoFutureThreeYear);
    },
    totalCnvFutureOneYear() {
      return (this.lucentisCnvFutureOneYear + this.byovizCnvFutureOneYear + this.biosimilarCnvFutureOneYear);
    },
    totalCnvFutureThreeYear() {
      return (this.lucentisCnvFutureThreeYear + this.byovizCnvFutureThreeYear + this.biosimilarCnvFutureThreeYear);
    },
    totalFutureSituationOneYear() {
      return (this.totalWamdFutureOneYear + this.totalDmeFutureOneYear + this.totalPdrFutureOneYear + this.totalRvoFutureOneYear + this.totalCnvFutureOneYear);
    },
    totalFutureSituationThreeYear() {
      return (this.totalWamdFutureThreeYear + this.totalDmeFutureThreeYear + this.totalPdrFutureThreeYear + this.totalRvoFutureThreeYear + this.totalCnvFutureThreeYear);
    },
    totalDifferenceOneYear() {
      const difference = (this.totalCurrentSituation - this.totalFutureSituationOneYear);
      return difference;
    },
    totalDifferenceThreeYear() {
      const difference = (this.totalCurrentSituation - this.totalFutureSituationThreeYear);
      return difference;
    },
  },
  methods: {
    isDifferenceNegative() {
      let diff = 0;
      if (this.timeframe === 1) {
        diff = (this.totalCurrentSituation - this.totalFutureSituationOneYear);
        if (diff > 0) this.differenceIsNegative = true;
        this.displayDifference = Math.abs(this.totalDifferenceOneYear);
      } else {
        diff = (this.totalCurrentSituation - this.totalFutureSituationThreeYear);
        if (diff > 0) this.differenceIsNegative = true;
        this.displayDifference = Math.abs(this.totalDifferenceThreeYear);
      }
    },
  },
  mounted() {
    this.isDifferenceNegative();
  },
};
</script>
