<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-center">
        <span class="graph-key current">Current Situation</span>
        <span class="graph-key future">Future Scenario</span>
        <span class="graph-key difference">Difference</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-2 medium-offset-1 bg-dark-grey border-right-thick-black pt-4 pb-4">
        <p class="text-white mb-0">Saving</p>
      </div>
      <div class="cell small-12 medium-8 bg-mid-dk-grey pt-4 pb-4">
        <p class="text-white mb-0 text-right">Cost/incremental cost</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2 medium-offset-1 bg-soft border-right-thick-black pt-4 pb-4">
        &nbsp;
      </div>
      <div class="cell small-12 medium-8 bg-soft pt-4 pb-4 relative">
        <span
          :style="{ width: Math.round(totalCurrent * scaleFraction) +'px'}"
          class="float-left graph-bar current"></span>
        <p class="mb-0 graph-figure right">£{{ totalCurrent | numeralFormat('0,0[.]') }}</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2 medium-offset-1 bg-soft border-right-thick-black pt-4 pb-4">
        &nbsp;
      </div>
      <div class="cell small-12 medium-8 bg-soft pt-4 pb-4 relative">
        <span
          :style="{ width: Math.round(totalFuture * scaleFraction) +'px'}"
          class="float-left graph-bar future"></span>
        <p class="mb-0 graph-figure right">£{{ totalFuture | numeralFormat('0,0[.]') }}</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2 medium-offset-1 bg-soft border-right-thick-black pt-4 pb-4 relative">
        <p v-if="differenceIsNegative === true" class="mb-0 graph-figure left">-£{{ displayDifference | numeralFormat('0,0[.]') }}</p>
        <span
          v-if="differenceIsNegative === true"
          :style="{ width: Math.round(displayDifference * scaleFraction) +'px'}"
          class="float-right graph-bar difference"></span>
      </div>
      <div class="cell small-12 medium-8 bg-soft pt-4 pb-4 relative">
        <span
          v-if="differenceIsNegative === false"
          :style="{ width: Math.round(displayDifference * scaleFraction) +'px'}"
          class="float-left graph-bar negative-difference"></span>
        <p v-if="differenceIsNegative === false" class="mb-0 graph-figure right">£{{ displayDifference | numeralFormat('0,0[.]') }}</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-2 medium-offset-1">
        <span class="float-left">{{ differenceScale }}</span>
        <span class="float-right">
          £-
        </span>
      </div>
      <div class="cell small-12 medium-8">
        <span class="fin-key">{{ scaleOne }}</span>
        <span class="fin-key">{{ scaleTwo }}</span>
        <span class="fin-key">{{ scaleThree }}</span>
        <span class="fin-key">{{ scaleFour }}</span>
        <span class="fin-key">{{ scaleFive }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h5 class="text-center">&pound;</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewPatientsGraph',
  data() {
    return {
      scaleFraction: 0.0000272,
      differenceScale: '-£5,000,000',
      scaleOne: '£5,000,000',
      scaleTwo: '£10,000,000',
      scaleThree: '£15,000,000',
      scaleFour: '£20,000,000',
      scaleFive: '£25,000,000',
      differenceIsNegative: false,
      displayDifference: 0,
    };
  },
  computed: {
    timeframe() {
      return this.$store.state.timeframe;
    },
    byovizCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdByovizYearOne + this.$store.getters.newPatientsCurrentCostDmeByovizYearOne + this.$store.getters.newPatientsCurrentCostPdrByovizYearOne + this.$store.getters.newPatientsCurrentCostRvoByovizYearOne + this.$store.getters.newPatientsCurrentCostCnvByovizYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearTwo + this.$store.getters.newPatientsCurrentCostDmeByovizYearTwo + this.$store.getters.newPatientsCurrentCostPdrByovizYearTwo + this.$store.getters.newPatientsCurrentCostRvoByovizYearTwo + this.$store.getters.newPatientsCurrentCostCnvByovizYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearThree + this.$store.getters.newPatientsCurrentCostDmeByovizYearThree + this.$store.getters.newPatientsCurrentCostPdrByovizYearThree + this.$store.getters.newPatientsCurrentCostRvoByovizYearThree + this.$store.getters.newPatientsCurrentCostCnvByovizYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOne + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOne + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOne + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOne + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwo + this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwo + this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwo + this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwo + this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearThree + this.$store.getters.newPatientsCurrentCostDmeLucentisYearThree + this.$store.getters.newPatientsCurrentCostPdrLucentisYearThree + this.$store.getters.newPatientsCurrentCostRvoLucentisYearThree + this.$store.getters.newPatientsCurrentCostCnvLucentisYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalCurrent() {
      return (this.byovizCurrent + this.lucentisCurrent + this.biosimilarCurrent);
    },
    byovizFutureOneYear() {
      return (this.$store.getters.newPatientsCurrentCostWamdByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoByovizYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvByovizYearOneFuture);
    },
    byovizFutureThreeYear() {
      return (
        this.$store.getters.newPatientsWamdByovizThreeYearFuture
        + this.$store.getters.newPatientsDmeByovizThreeYearFuture
        + this.$store.getters.newPatientsPdrByovizThreeYearFuture
        + this.$store.getters.newPatientsRvoByovizThreeYearFuture
        + this.$store.getters.newPatientsCnvByovizThreeYearFuture);
    },
    lucentisFutureOneYear() {
      return (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOneFuture);
    },
    lucentisFutureThreeYear() {
      return (this.$store.getters.newPatientsWamdLucentisThreeYearFuture
        + this.$store.getters.newPatientsDmeLucentisThreeYearFuture
        + this.$store.getters.newPatientsPdrLucentisThreeYearFuture
        + this.$store.getters.newPatientsRvoLucentisThreeYearFuture
        + this.$store.getters.newPatientsCnvLucentisThreeYearFuture);
    },
    biosimilarFutureOneYear() {
      return (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOneFuture);
    },
    biosimilarFutureThreeYear() {
      return (this.$store.getters.newPatientsWamdBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsDmeBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsPdrBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsRvoBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsCnvBiosimilarThreeYearFuture);
    },
    totalFuture() {
      let total = 0;
      if (this.timeframe === 1) {
        total = (this.byovizFutureOneYear + this.lucentisFutureOneYear + this.biosimilarFutureOneYear);
      } else {
        total = (this.byovizFutureThreeYear + this.lucentisFutureThreeYear + this.biosimilarFutureThreeYear);
      }
      return total;
    },
    difference() {
      return (this.totalCurrent - this.totalFuture);
    },
  },
  methods: {
    isDifferenceNegative() {
      const diff = (this.totalCurrent - this.totalFuture);
      if (diff > 0) this.differenceIsNegative = true;
      this.displayDifference = Math.abs(this.difference);
    },
    calculateScale() {
      if (this.totalCurrent < 3125000) {
        this.scaleFraction = 0.000165;
        this.differenceScale = '-£625,000';
        this.scaleOne = '£625,000';
        this.scaleTwo = '£1,250,000';
        this.scaleThree = '£1,875,000';
        this.scaleFour = '£2,500,000';
        this.scaleFive = '£3,125,000';
      }
      if (this.totalCurrent > 3125000) {
        this.scaleFraction = 0.000082;
        this.differenceScale = '-£1,250,000';
        this.scaleOne = '£1,250,000';
        this.scaleTwo = '£2,500,000';
        this.scaleThree = '£3,750,000';
        this.scaleFour = '£5,000,000';
        this.scaleFive = '£6,250,000';
      }
      if (this.totalCurrent > 6250000) {
        this.scaleFraction = 0.000043;
        this.differenceScale = '-£2,500,000';
        this.scaleOne = '£2,500,000';
        this.scaleTwo = '£5,000,000';
        this.scaleThree = '£7,500,000';
        this.scaleFour = '£10,000,000';
        this.scaleFive = '£12,500,000';
      }
      if (this.totalCurrent > 12500000) {
        this.scaleFraction = 0.000022;
        this.differenceScale = '-£5,000,000';
        this.scaleOne = '£5,000,000';
        this.scaleTwo = '£10,000,000';
        this.scaleThree = '£15,000,000';
        this.scaleFour = '£20,000,000';
        this.scaleFive = '£25,000,000';
      }
      if (this.totalCurrent > 25000000) {
        this.scaleFraction = 0.0000109;
        this.differenceScale = '-£10,000,000';
        this.scaleOne = '£10,000,000';
        this.scaleTwo = '£20,000,000';
        this.scaleThree = '£30,000,000';
        this.scaleFour = '£40,000,000';
        this.scaleFive = '£50,000,000';
      }
      if (this.totalCurrent > 50000000) {
        this.scaleFraction = 0.0000055;
        this.differenceScale = '-£20,000,000';
        this.scaleOne = '£20,000,000';
        this.scaleTwo = '£40,000,000';
        this.scaleThree = '£60,000,000';
        this.scaleFour = '£80,000,000';
        this.scaleFive = '£100,000,000';
      }
      if (this.totalCurrent > 100000000) {
        this.scaleFraction = 0.00000285;
        this.differenceScale = '-£40,000,000';
        this.scaleOne = '£40,000,000';
        this.scaleTwo = '£80,000,000';
        this.scaleThree = '£120,000,000';
        this.scaleFour = '£160,000,000';
        this.scaleFive = '£200,000,000';
      }
      if (this.totalCurrent > 200000000) {
        this.scaleFraction = 0.00000145;
        this.differenceScale = '-£80,000,000';
        this.scaleOne = '£80,000,000';
        this.scaleTwo = '£160,000,000';
        this.scaleThree = '£240,000,000';
        this.scaleFour = '£320,000,000';
        this.scaleFive = '£400,000,000';
      }
      if (this.totalCurrent > 400000000) {
        this.scaleFraction = 0.00000073;
        this.differenceScale = '-£160,000,000';
        this.scaleOne = '£160,000,000';
        this.scaleTwo = '£320,000,000';
        this.scaleThree = '£480,000,000';
        this.scaleFour = '£640,000,000';
        this.scaleFive = '£800,000,000';
      }
      if (this.totalCurrent > 800000000) {
        this.scaleFraction = 0.000000385;
        this.differenceScale = '-£320,000,000';
        this.scaleOne = '£320,000,000';
        this.scaleTwo = '£640,000,000';
        this.scaleThree = '£960,000,000';
        this.scaleFour = '£1,280,000,000';
        this.scaleFive = '£1,600,000,000';
      }
    },
  },
  mounted() {
    this.calculateScale();
    this.isDifferenceNegative();
  },
};
</script>
