<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 text-center">
        <h5>Current Situation</h5>
      </div>
      <div class="cell small-12 medium-6 text-center">
        <h5>Future Situation</h5>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="current-cell">wAMD</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">DME</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">PDR</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">RVO</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">CNV</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="future-cell">wAMD</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">DME</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">PDR</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">RVO</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">CNV</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('byoviz')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Byooviz</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ byovizWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{  byovizDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('lucentis')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Lucentis</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ lucentisWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('biosimilars 2')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Biosimilars 2</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ biosimilarWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Total</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ totalWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-10">
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Total current situation:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">&pound;{{ totalCurrentSituation | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Total future situation:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">&pound;{{ totalFutureSituation | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Difference:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">
          <i class="plain" v-if="differenceIsNegative">-</i>
          &pound;{{ displayDifference | numeralFormat('0,0[.]') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllPatientsTable',
  data() {
    return {
      differenceIsNegative: false,
      displayDifference: 0,
    };
  },
  computed: {
    drugs() {
      return this.$store.state.drugs;
    },
    timeframe() {
      return this.$store.state.timeframe;
    },
    lucentisWamdNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizWamdNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarWamdNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisDmeNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizDmeNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarDmeNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisPdrNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizPdrNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarPdrNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisRvoNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizRvoNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarRvoNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCnvNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizCnvNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCnvNew() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisWamdSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizWamdSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarWamdSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisDmeSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizDmeSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarDmeSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisPdrSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizPdrSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarPdrSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisRvoSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizRvoSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarRvoSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCnvSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizCnvSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCnvSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisWamd() { return this.lucentisWamdSwitch + this.lucentisWamdNew; },
    byovizWamd() { return this.byovizWamdSwitch + this.byovizWamdNew; },
    biosimilarWamd() { return this.biosimilarWamdSwitch + this.biosimilarWamdNew; },
    lucentisDme() { return this.lucentisDmeSwitch + this.lucentisDmeNew; },
    byovizDme() { return this.byovizDmeSwitch + this.byovizDmeNew; },
    biosimilarDme() { return this.biosimilarDmeSwitch + this.biosimilarDmeNew; },
    lucentisPdr() { return this.lucentisPdrSwitch + this.lucentisPdrNew; },
    byovizPdr() { return this.byovizPdrSwitch + this.byovizPdrNew; },
    biosimilarPdr() { return this.biosimilarPdrSwitch + this.biosimilarPdrNew; },
    lucentisRvo() { return this.lucentisRvoSwitch + this.lucentisRvoNew; },
    byovizRvo() { return this.byovizRvoSwitch + this.byovizRvoNew; },
    biosimilarRvo() { return this.biosimilarRvoSwitch + this.biosimilarRvoNew; },
    lucentisCnv() { return this.lucentisCnvSwitch + this.lucentisCnvNew; },
    byovizCnv() { return this.byovizCnvSwitch + this.byovizCnvNew; },
    biosimilarCnv() { return this.biosimilarCnvSwitch + this.biosimilarCnvNew; },
    totalWamd() {
      return (this.lucentisWamd + this.byovizWamd + this.biosimilarWamd);
    },
    totalDme() {
      return (this.lucentisDme + this.byovizDme + this.biosimilarDme);
    },
    totalPdr() {
      return (this.lucentisPdr + this.byovizPdr + this.biosimilarPdr);
    },
    totalRvo() {
      return (this.lucentisRvo + this.byovizRvo + this.biosimilarRvo);
    },
    totalCnv() {
      return (this.lucentisCnv + this.byovizCnv + this.biosimilarCnv);
    },
    totalCurrentSituation() {
      return (this.totalWamd + this.totalDme + this.totalPdr + this.totalRvo + this.totalCnv);
    },
    lucentisWamdFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostWamdLucentisYearOneFuture;
      return this.$store.getters.newPatientsWamdLucentisThreeYearFuture;
    },
    byovizWamdFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostWamdByovizYearOneFuture;
      return this.$store.getters.newPatientsWamdByovizThreeYearFuture;
    },
    biosimilarWamdFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOneFuture;
      return this.$store.getters.newPatientsWamdBiosimilarThreeYearFuture;
    },
    lucentisDmeFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostDmeLucentisYearOneFuture;
      return this.$store.getters.newPatientsDmeLucentisThreeYearFuture;
    },
    byovizDmeFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostDmeByovizYearOneFuture;
      return this.$store.getters.newPatientsDmeByovizThreeYearFuture;
    },
    biosimilarDmeFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOneFuture;
      return this.$store.getters.newPatientsDmeBiosimilarThreeYearFuture;
    },
    lucentisPdrFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostPdrLucentisYearOneFuture;
      return this.$store.getters.newPatientsPdrLucentisThreeYearFuture;
    },
    byovizPdrFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostPdrByovizYearOneFuture;
      return this.$store.getters.newPatientsPdrByovizThreeYearFuture;
    },
    biosimilarPdrFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOneFuture;
      return this.$store.getters.newPatientsPdrBiosimilarThreeYearFuture;
    },
    lucentisRvoFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostRvoLucentisYearOneFuture;
      return this.$store.getters.newPatientsRvoLucentisThreeYearFuture;
    },
    byovizRvoFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostRvoByovizYearOneFuture;
      return this.$store.getters.newPatientsRvoByovizThreeYearFuture;
    },
    biosimilarRvoFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOneFuture;
      return this.$store.getters.newPatientsRvoBiosimilarThreeYearFuture;
    },
    lucentisCnvFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostCnvLucentisYearOneFuture;
      return this.$store.getters.newPatientsCnvLucentisThreeYearFuture;
    },
    byovizCnvFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostCnvByovizYearOneFuture;
      return this.$store.getters.newPatientsCnvByovizThreeYearFuture;
    },
    biosimilarCnvFutureNew() {
      if (this.timeframe === 1) return this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOneFuture;
      return this.$store.getters.newPatientsCnvBiosimilarThreeYearFuture;
    },
    lucentisWamdFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureLucentisWamd;
    },
    byovizWamdFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostWamdByovizYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureByovizWamd;
    },
    biosimilarWamdFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarWamd;
    },
    lucentisDmeFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureLucentisDme;
    },
    byovizDmeFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostDmeByovizYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureByovizDme;
    },
    biosimilarDmeFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarDme;
    },
    lucentisPdrFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureLucentisPdr;
    },
    byovizPdrFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostPdrByovizYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureByovizPdr;
    },
    biosimilarPdrFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarPdr;
    },
    lucentisRvoFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureLucentisRvo;
    },
    byovizRvoFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostRvoByovizYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureByovizRvo;
    },
    biosimilarRvoFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarRvo;
    },
    lucentisCnvFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureLucentisCnv;
    },
    byovizCnvFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostCnvByovizYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureByovizCnv;
    },
    biosimilarCnvFutureSwitch() {
      if (this.timeframe === 1) return this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOneFuture;
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarCnv;
    },
    byovizWamdFuture() { return (this.byovizWamdFutureNew + this.byovizWamdFutureSwitch); },
    byovizDmeFuture() { return (this.byovizDmeFutureNew + this.byovizDmeFutureSwitch); },
    byovizPdrFuture() { return (this.byovizPdrFutureNew + this.byovizPdrFutureSwitch); },
    byovizRvoFuture() { return (this.byovizRvoFutureNew + this.byovizRvoFutureSwitch); },
    byovizCnvFuture() { return (this.byovizCnvFutureNew + this.byovizCnvFutureSwitch); },
    lucentisWamdFuture() { return (this.lucentisWamdFutureNew + this.lucentisWamdFutureSwitch); },
    lucentisDmeFuture() { return (this.lucentisDmeFutureNew + this.lucentisDmeFutureSwitch); },
    lucentisPdrFuture() { return (this.lucentisPdrFutureNew + this.lucentisPdrFutureSwitch); },
    lucentisRvoFuture() { return (this.lucentisRvoFutureNew + this.lucentisRvoFutureSwitch); },
    lucentisCnvFuture() { return (this.lucentisCnvFutureNew + this.lucentisCnvFutureSwitch); },
    biosimilarWamdFuture() { return (this.biosimilarWamdFutureNew + this.biosimilarWamdFutureSwitch); },
    biosimilarDmeFuture() { return (this.biosimilarDmeFutureNew + this.biosimilarDmeFutureSwitch); },
    biosimilarPdrFuture() { return (this.biosimilarPdrFutureNew + this.biosimilarPdrFutureSwitch); },
    biosimilarRvoFuture() { return (this.biosimilarRvoFutureNew + this.biosimilarRvoFutureSwitch); },
    biosimilarCnvFuture() { return (this.biosimilarCnvFutureNew + this.biosimilarCnvFutureSwitch); },
    totalWamdFutureNew() {
      return (this.lucentisWamdFutureNew + this.byovizWamdFutureNew + this.biosimilarWamdFutureNew);
    },
    totalDmeFutureNew() {
      return (this.lucentisDmeFutureNew + this.byovizDmeFutureNew + this.biosimilarDmeFutureNew);
    },
    totalPdrFutureNew() {
      return (this.lucentisPdrFutureNew + this.byovizPdrFutureNew + this.biosimilarPdrFutureNew);
    },
    totalRvoFutureNew() {
      return (this.lucentisRvoFutureNew + this.byovizRvoFutureNew + this.biosimilarRvoFutureNew);
    },
    totalCnvFutureNew() {
      return (this.lucentisCnvFutureNew + this.byovizCnvFutureNew + this.biosimilarCnvFutureNew);
    },
    totalWamdFutureSwitch() {
      return (this.lucentisWamdFutureSwitch + this.byovizWamdFutureSwitch + this.biosimilarWamdFutureSwitch);
    },
    totalDmeFutureSwitch() {
      return (this.lucentisDmeFutureSwitch + this.byovizDmeFutureSwitch + this.biosimilarDmeFutureSwitch);
    },
    totalPdrFutureSwitch() {
      return (this.lucentisPdrFutureSwitch + this.byovizPdrFutureSwitch + this.biosimilarPdrFutureSwitch);
    },
    totalRvoFutureSwitch() {
      return (this.lucentisRvoFutureSwitch + this.byovizRvoFutureSwitch + this.biosimilarRvoFutureSwitch);
    },
    totalCnvFutureSwitch() {
      return (this.lucentisCnvFutureSwitch + this.byovizCnvFutureSwitch + this.biosimilarCnvFutureSwitch);
    },
    totalWamdFuture() { return this.totalWamdFutureNew + this.totalWamdFutureSwitch; },
    totalDmeFuture() { return this.totalDmeFutureNew + this.totalDmeFutureSwitch; },
    totalPdrFuture() { return this.totalPdrFutureNew + this.totalPdrFutureSwitch; },
    totalRvoFuture() { return this.totalRvoFutureNew + this.totalRvoFutureSwitch; },
    totalCnvFuture() { return this.totalCnvFutureNew + this.totalCnvFutureSwitch; },
    totalFutureSituation() {
      return (this.totalWamdFuture + this.totalDmeFuture + this.totalPdrFuture + this.totalRvoFuture + this.totalCnvFuture);
    },
    totalDifference() {
      const difference = (this.totalCurrentSituation - this.totalFutureSituation);
      return difference;
    },
  },
  methods: {
    isDifferenceNegative() {
      const diff = (this.totalCurrentSituation - this.totalFutureSituation);
      if (diff > 0) this.differenceIsNegative = true;
      this.displayDifference = Math.abs(this.totalDifference);
    },
  },
  mounted() {
    this.isDifferenceNegative();
  },
};
</script>
