<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3 medium-offset-3">
        <h5 class="text-center">Current Situation</h5>
      </div>
      <div class="cell small-12 medium-3">
        <h5 class="text-center">Future Scenario</h5>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Byooviz</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ byovizCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ byovizFuture | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="wide orange-line" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Lucentis</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ lucentisCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ lucentisFuture | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div v-if="drugs.includes('biosimilars 2')" class="grid-x grid-padding-x">
      <div class="cell small-12">
        <hr class="wide orange-line" />
      </div>
    </div>
    <div v-if="drugs.includes('biosimilars 2')" class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">biosimilars 2</button>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ biosimilarCurrent | numeralFormat('0,0[.]00') }}</span>
      </div>
      <div class="cell small-12 medium-3">
        <span class="input-fixed text-center">&pound;{{ biosimilarFuture | numeralFormat('0,0[.]00') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-9">
        <hr class="wide orange-line" />
      </div>
      <div class="cell small-3">
        <h5>Difference</h5>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-active">Total</button>
      </div>
      <div class="cell small-12 medium-3">
        <button class="button expanded blue-alt">
          &pound;{{ totalCurrent | numeralFormat('0,0[.]00') }}
        </button>
      </div>
      <div class="cell small-12 medium-3">
        <button class="button expanded">
          &pound;{{ totalFuture | numeralFormat('0,0[.]00') }}
        </button>
      </div>
      <div class="cell small-12 medium-3">
        <button class="button expanded grey">
          <i class="plain" v-if="differenceIsNegative">-</i>
          &pound;{{ displayDifference | numeralFormat('0,0[.]00') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllPatientsTotals',
  data() {
    return {
      differenceIsNegative: false,
      displayDifference: 0,
    };
  },
  computed: {
    drugs() { return this.$store.state.drugs; },
    timeframe() { return this.$store.state.timeframe; },
    byovizCurrentNew() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdByovizYearOne + this.$store.getters.newPatientsCurrentCostDmeByovizYearOne + this.$store.getters.newPatientsCurrentCostPdrByovizYearOne + this.$store.getters.newPatientsCurrentCostRvoByovizYearOne + this.$store.getters.newPatientsCurrentCostCnvByovizYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearTwo + this.$store.getters.newPatientsCurrentCostDmeByovizYearTwo + this.$store.getters.newPatientsCurrentCostPdrByovizYearTwo + this.$store.getters.newPatientsCurrentCostRvoByovizYearTwo + this.$store.getters.newPatientsCurrentCostCnvByovizYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearThree + this.$store.getters.newPatientsCurrentCostDmeByovizYearThree + this.$store.getters.newPatientsCurrentCostPdrByovizYearThree + this.$store.getters.newPatientsCurrentCostRvoByovizYearThree + this.$store.getters.newPatientsCurrentCostCnvByovizYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCurrentNew() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOne + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOne + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOne + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOne + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwo + this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwo + this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwo + this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwo + this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearThree + this.$store.getters.newPatientsCurrentCostDmeLucentisYearThree + this.$store.getters.newPatientsCurrentCostPdrLucentisYearThree + this.$store.getters.newPatientsCurrentCostRvoLucentisYearThree + this.$store.getters.newPatientsCurrentCostCnvLucentisYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCurrentNew() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalCurrentNew() {
      return (this.byovizCurrentNew + this.lucentisCurrentNew + this.biosimilarCurrentNew);
    },
    byovizFutureNew() {
      if (this.timeframe === 1) {
        return (this.$store.getters.newPatientsCurrentCostWamdByovizYearOneFuture
          + this.$store.getters.newPatientsCurrentCostDmeByovizYearOneFuture
          + this.$store.getters.newPatientsCurrentCostPdrByovizYearOneFuture
          + this.$store.getters.newPatientsCurrentCostRvoByovizYearOneFuture
          + this.$store.getters.newPatientsCurrentCostCnvByovizYearOneFuture);
      }
      return (
        this.$store.getters.newPatientsWamdByovizThreeYearFuture
        + this.$store.getters.newPatientsDmeByovizThreeYearFuture
        + this.$store.getters.newPatientsPdrByovizThreeYearFuture
        + this.$store.getters.newPatientsRvoByovizThreeYearFuture
        + this.$store.getters.newPatientsCnvByovizThreeYearFuture);
    },
    lucentisFutureNew() {
      if (this.timeframe === 1) {
        return (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOneFuture);
      }
      return (this.$store.getters.newPatientsWamdLucentisThreeYearFuture
        + this.$store.getters.newPatientsDmeLucentisThreeYearFuture
        + this.$store.getters.newPatientsPdrLucentisThreeYearFuture
        + this.$store.getters.newPatientsRvoLucentisThreeYearFuture
        + this.$store.getters.newPatientsCnvLucentisThreeYearFuture);
    },
    biosimilarFutureNew() {
      if (this.timeframe === 1) {
        return (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOneFuture
        + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOneFuture);
      }
      return (this.$store.getters.newPatientsWamdBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsDmeBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsPdrBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsRvoBiosimilarThreeYearFuture
        + this.$store.getters.newPatientsCnvBiosimilarThreeYearFuture);
    },
    totalFutureNew() {
      return (this.byovizFutureNew + this.lucentisFutureNew + this.biosimilarFutureNew);
    },
    byovizCurrentSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearOne + this.$store.getters.switchPatientsCurrentCostDmeByovizYearOne + this.$store.getters.switchPatientsCurrentCostPdrByovizYearOne + this.$store.getters.switchPatientsCurrentCostRvoByovizYearOne + this.$store.getters.switchPatientsCurrentCostCnvByovizYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearTwo + this.$store.getters.switchPatientsCurrentCostDmeByovizYearTwo + this.$store.getters.switchPatientsCurrentCostPdrByovizYearTwo + this.$store.getters.switchPatientsCurrentCostRvoByovizYearTwo + this.$store.getters.switchPatientsCurrentCostCnvByovizYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearThree + this.$store.getters.switchPatientsCurrentCostDmeByovizYearThree + this.$store.getters.switchPatientsCurrentCostPdrByovizYearThree + this.$store.getters.switchPatientsCurrentCostRvoByovizYearThree + this.$store.getters.switchPatientsCurrentCostCnvByovizYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCurrentSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOne + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOne + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOne + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOne + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearThree + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearThree + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearThree + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearThree + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCurrentSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalCurrentSwitch() {
      return (this.byovizCurrentSwitch + this.lucentisCurrentSwitch + this.biosimilarCurrentSwitch);
    },
    byovizCurrent() {
      return (this.byovizCurrentNew + this.byovizCurrentSwitch);
    },
    biosimilarCurrent() {
      return (this.biosimilarCurrentNew + this.biosimilarCurrentSwitch);
    },
    lucentisCurrent() {
      return (this.lucentisCurrentNew + this.lucentisCurrentSwitch);
    },
    byovizFutureSwitch() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvByovizYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureByovizWamd
        + this.$store.getters.switchPatientsThreeYearFutureByovizDme
        + this.$store.getters.switchPatientsThreeYearFutureByovizPdr
        + this.$store.getters.switchPatientsThreeYearFutureByovizRvo
        + this.$store.getters.switchPatientsThreeYearFutureByovizCnv
      );
    },
    lucentisFutureSwitch() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureLucentisWamd
        + this.$store.getters.switchPatientsThreeYearFutureLucentisDme
        + this.$store.getters.switchPatientsThreeYearFutureLucentisPdr
        + this.$store.getters.switchPatientsThreeYearFutureLucentisRvo
        + this.$store.getters.switchPatientsThreeYearFutureLucentisCnv
      );
    },
    biosimilarFutureSwitch() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureBiosimilarWamd
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarDme
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarPdr
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarRvo
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarCnv
      );
    },
    byovizFuture() {
      return (this.byovizFutureNew + this.byovizFutureSwitch);
    },
    biosimilarFuture() {
      return (this.biosimilarFutureNew + this.biosimilarFutureSwitch);
    },
    lucentisFuture() {
      return (this.lucentisFutureNew + this.lucentisFutureSwitch);
    },
    totalFutureSwitch() {
      return (this.byovizFutureSwitch + this.lucentisFutureSwitch + this.biosimilarFutureSwitch);
    },
    totalCurrent() {
      return (this.totalCurrentNew + this.totalCurrentSwitch);
    },
    totalFuture() {
      return (this.totalFutureNew + this.totalFutureSwitch);
    },
    difference() {
      const difference = (this.totalCurrent - this.totalFuture);
      return difference;
    },
  },
  methods: {
    isDifferenceNegative() {
      const diff = (this.totalCurrent - this.totalFuture);
      if (diff > 0) this.differenceIsNegative = true;
      this.displayDifference = Math.abs(this.difference);
    },
  },
  mounted() {
    this.isDifferenceNegative();
  },
};
</script>
