<template>
  <div class="email-form">
    <label>Email address</label>
    <input type="email" v-model="emailAddress">
    <button @click="sendReport" class="button">Send Report</button>
    &nbsp;
    <button @click="cancel" class="button">Cancel</button>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'EmailForm',
  data() {
    return {
      emailAddress: '',
      showMessage: false,
    };
  },
  computed: {
    timeframe() { return this.$store.state.timeframe; },
    reportTitle() { return this.$store.state.hospital; },
    newPatients() {
      if (this.timeframe === 1) {
        return this.$store.getters.totalNewPatients;
      }
      return (this.$store.getters.totalNewPatients * 3);
    },
    switchPatients() { return this.$store.getters.totalSwitchPatients; },
    allPatients() { return this.$store.getters.totalAllPatients; },
    patientTypes() { return this.$store.state.patientTypes; },
    drugs() { return this.$store.state.drugs; },
    byovizYearOneShare: {
      get() { return this.$store.state.byovizYearOneShare; },
      set(value) { this.$store.commit('updatebyovizYearOneShare', value); },
    },
    byovizYearTwoShare: {
      get() { return this.$store.state.byovizYearTwoShare; },
      set(value) { this.$store.commit('updatebyovizYearTwoShare', value); },
    },
    byovizYearThreeShare: {
      get() { return this.$store.state.byovizYearThreeShare; },
      set(value) { this.$store.commit('updatebyovizYearThreeShare', value); },
    },
    lucentisYearOneShare: {
      get() { return this.$store.state.lucentisYearOneShare; },
      set(value) { this.$store.commit('updatelucentisYearOneShare', value); },
    },
    lucentisYearTwoShare: {
      get() { return this.$store.state.lucentisYearTwoShare; },
      set(value) { this.$store.commit('updatelucentisYearTwoShare', value); },
    },
    lucentisYearThreeShare: {
      get() { return this.$store.state.lucentisYearThreeShare; },
      set(value) { this.$store.commit('updatelucentisYearThreeShare', value); },
    },
    biosimilarYearOneShare: {
      get() { return this.$store.state.biosimilarYearOneShare; },
      set(value) { this.$store.commit('updatebiosimilarYearOneShare', value); },
    },
    biosimilarYearTwoShare: {
      get() { return this.$store.state.biosimilarYearTwoShare; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoShare', value); },
    },
    biosimilarYearThreeShare: {
      get() { return this.$store.state.biosimilarYearThreeShare; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeShare', value); },
    },
    byovizYearOneFutureShare: {
      get() { return this.$store.state.byovizYearOneFutureShare; },
      set(value) { this.$store.commit('updatebyovizYearOneFutureShare', value); },
    },
    byovizYearTwoFutureShare: {
      get() { return this.$store.state.byovizYearTwoFutureShare; },
      set(value) { this.$store.commit('updatebyovizYearTwoFutureShare', value); },
    },
    byovizYearThreeFutureShare: {
      get() { return this.$store.state.byovizYearThreeFutureShare; },
      set(value) { this.$store.commit('updatebyovizYearThreeFutureShare', value); },
    },
    lucentisYearOneFutureShare: {
      get() { return this.$store.state.lucentisYearOneFutureShare; },
      set(value) { this.$store.commit('updatelucentisYearOneFutureShare', value); },
    },
    lucentisYearTwoFutureShare: {
      get() { return this.$store.state.lucentisYearTwoFutureShare; },
      set(value) { this.$store.commit('updatelucentisYearTwoFutureShare', value); },
    },
    lucentisYearThreeFutureShare: {
      get() { return this.$store.state.lucentisYearThreeFutureShare; },
      set(value) { this.$store.commit('updatelucentisYearThreeFutureShare', value); },
    },
    biosimilarYearOneFutureShare: {
      get() { return this.$store.state.biosimilarYearOneFutureShare; },
      set(value) { this.$store.commit('updatebiosimilarYearOneFutureShare', value); },
    },
    biosimilarYearTwoFutureShare: {
      get() { return this.$store.state.biosimilarYearTwoFutureShare; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoFutureShare', value); },
    },
    biosimilarYearThreeFutureShare: {
      get() { return this.$store.state.biosimilarYearThreeFutureShare; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeFutureShare', value); },
    },
    byovizYearOneShareSwitch: {
      get() { return this.$store.state.byovizYearOneShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearOneShareSwitch', value); },
    },
    byovizYearTwoShareSwitch: {
      get() { return this.$store.state.byovizYearTwoShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearTwoShareSwitch', value); },
    },
    byovizYearThreeShareSwitch: {
      get() { return this.$store.state.byovizYearThreeShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearThreeShareSwitch', value); },
    },
    lucentisYearOneShareSwitch: {
      get() { return this.$store.state.lucentisYearOneShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearOneShareSwitch', value); },
    },
    lucentisYearTwoShareSwitch: {
      get() { return this.$store.state.lucentisYearTwoShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearTwoShareSwitch', value); },
    },
    lucentisYearThreeShareSwitch: {
      get() { return this.$store.state.lucentisYearThreeShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearThreeShareSwitch', value); },
    },
    biosimilarYearOneShareSwitch: {
      get() { return this.$store.state.biosimilarYearOneShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearOneShareSwitch', value); },
    },
    biosimilarYearTwoShareSwitch: {
      get() { return this.$store.state.biosimilarYearTwoShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoShareSwitch', value); },
    },
    biosimilarYearThreeShareSwitch: {
      get() { return this.$store.state.biosimilarYearThreeShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeShareSwitch', value); },
    },
    byovizYearOneFutureShareSwitch: {
      get() { return this.$store.state.byovizYearOneFutureShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearOneFutureShareSwitch', value); },
    },
    byovizYearTwoFutureShareSwitch: {
      get() { return this.$store.state.byovizYearTwoFutureShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearTwoFutureShareSwitch', value); },
    },
    byovizYearThreeFutureShareSwitch: {
      get() { return this.$store.state.byovizYearThreeFutureShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearThreeFutureShareSwitch', value); },
    },
    lucentisYearOneFutureShareSwitch: {
      get() { return this.$store.state.lucentisYearOneFutureShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearOneFutureShareSwitch', value); },
    },
    lucentisYearTwoFutureShareSwitch: {
      get() { return this.$store.state.lucentisYearTwoFutureShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearTwoFutureShareSwitch', value); },
    },
    lucentisYearThreeFutureShareSwitch: {
      get() { return this.$store.state.lucentisYearThreeFutureShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearThreeFutureShareSwitch', value); },
    },
    biosimilarYearOneFutureShareSwitch: {
      get() { return this.$store.state.biosimilarYearOneFutureShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearOneFutureShareSwitch', value); },
    },
    biosimilarYearTwoFutureShareSwitch: {
      get() { return this.$store.state.biosimilarYearTwoFutureShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoFutureShareSwitch', value); },
    },
    biosimilarYearThreeFutureShareSwitch: {
      get() { return this.$store.state.biosimilarYearThreeFutureShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeFutureShareSwitch', value); },
    },
    lucentisWamd() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizWamd() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarWamd() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisDme() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizDme() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarDme() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisPdr() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizPdr() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarPdr() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisRvo() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizRvo() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarRvo() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCnv() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvLucentisYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvLucentisYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizCnv() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvByovizYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvByovizYearTwo;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvByovizYearThree;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCnv() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwoNew = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearThreeNew = this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwo = (yearOne + yearTwoNew);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalWamd() {
      return (this.lucentisWamd + this.byovizWamd + this.biosimilarWamd);
    },
    totalDme() {
      return (this.lucentisDme + this.byovizDme + this.biosimilarDme);
    },
    totalPdr() {
      return (this.lucentisPdr + this.byovizPdr + this.biosimilarPdr);
    },
    totalRvo() {
      return (this.lucentisRvo + this.byovizRvo + this.biosimilarRvo);
    },
    totalCnv() {
      return (this.lucentisCnv + this.byovizCnv + this.biosimilarCnv);
    },
    totalCurrentSituation() {
      return (
        this.totalWamd + this.totalDme + this.totalPdr + this.totalRvo + this.totalCnv
      );
    },
    lucentisWamdFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostWamdLucentisYearOneFuture;
      }
      return this.$store.getters.newPatientsWamdLucentisThreeYearFuture;
    },
    byovizWamdFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostWamdByovizYearOneFuture;
      }
      return this.$store.getters.newPatientsWamdByovizThreeYearFuture;
    },
    biosimilarWamdFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOneFuture;
      }
      return this.$store.getters.newPatientsWamdBiosimilarThreeYearFuture;
    },
    lucentisDmeFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostDmeLucentisYearOneFuture;
      }
      return this.$store.getters.newPatientsDmeLucentisThreeYearFuture;
    },
    byovizDmeFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostDmeByovizYearOneFuture;
      }
      return this.$store.getters.newPatientsDmeByovizThreeYearFuture;
    },
    biosimilarDmeFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOneFuture;
      }
      return this.$store.getters.newPatientsDmeBiosimilarThreeYearFuture;
    },
    lucentisPdrFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostPdrLucentisYearOneFuture;
      }
      return this.$store.getters.newPatientsPdrLucentisThreeYearFuture;
    },
    byovizPdrFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostPdrByovizYearOneFuture;
      }
      return this.$store.getters.newPatientsPdrByovizThreeYearFuture;
    },
    biosimilarPdrFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOneFuture;
      }
      return this.$store.getters.newPatientsPdrBiosimilarThreeYearFuture;
    },
    lucentisRvoFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostRvoLucentisYearOneFuture;
      }
      return this.$store.getters.newPatientsRvoLucentisThreeYearFuture;
    },
    byovizRvoFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostRvoByovizYearOneFuture;
      }
      return this.$store.getters.newPatientsRvoByovizThreeYearFuture;
    },
    biosimilarRvoFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOneFuture;
      }
      return this.$store.getters.newPatientsRvoBiosimilarThreeYearFuture;
    },
    lucentisCnvFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostCnvLucentisYearOneFuture;
      }
      return this.$store.getters.newPatientsCnvLucentisThreeYearFuture;
    },
    byovizCnvFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostCnvByovizYearOneFuture;
      }
      return this.$store.getters.newPatientsCnvByovizThreeYearFuture;
    },
    biosimilarCnvFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOneFuture;
      }
      return this.$store.getters.newPatientsCnvBiosimilarThreeYearFuture;
    },
    totalWamdFuture() {
      return (this.lucentisWamdFuture + this.byovizWamdFuture + this.biosimilarWamdFuture);
    },
    totalDmeFuture() {
      return (this.lucentisDmeFuture + this.byovizDmeFuture + this.biosimilarDmeFuture);
    },
    totalPdrFuture() {
      return (this.lucentisPdrFuture + this.byovizPdrFuture + this.biosimilarPdrFuture);
    },
    totalRvoFuture() {
      return (this.lucentisRvoFuture + this.byovizRvoFuture + this.biosimilarRvoFuture);
    },
    totalCnvFuture() {
      return (this.lucentisCnvFuture + this.byovizCnvFuture + this.biosimilarCnvFuture);
    },
    totalFutureSituation() {
      return (this.totalWamdFuture + this.totalDmeFuture + this.totalPdrFuture + this.totalRvoFuture + this.totalCnvFuture);
    },
    totalDifference() {
      const difference = (this.totalCurrentSituation - this.totalFutureSituation);
      return difference;
    },
    graphByovizCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdByovizYearOne + this.$store.getters.newPatientsCurrentCostDmeByovizYearOne + this.$store.getters.newPatientsCurrentCostPdrByovizYearOne + this.$store.getters.newPatientsCurrentCostRvoByovizYearOne + this.$store.getters.newPatientsCurrentCostCnvByovizYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearTwo + this.$store.getters.newPatientsCurrentCostDmeByovizYearTwo + this.$store.getters.newPatientsCurrentCostPdrByovizYearTwo + this.$store.getters.newPatientsCurrentCostRvoByovizYearTwo + this.$store.getters.newPatientsCurrentCostCnvByovizYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearThree + this.$store.getters.newPatientsCurrentCostDmeByovizYearThree + this.$store.getters.newPatientsCurrentCostPdrByovizYearThree + this.$store.getters.newPatientsCurrentCostRvoByovizYearThree + this.$store.getters.newPatientsCurrentCostCnvByovizYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    graphLucentisCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOne + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOne + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOne + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOne + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwo + this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwo + this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwo + this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwo + this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearThree + this.$store.getters.newPatientsCurrentCostDmeLucentisYearThree + this.$store.getters.newPatientsCurrentCostPdrLucentisYearThree + this.$store.getters.newPatientsCurrentCostRvoLucentisYearThree + this.$store.getters.newPatientsCurrentCostCnvLucentisYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    graphBiosimilarCurrent() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOne + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOne);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearTwo + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearTwo);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearThree + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearThree);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    graphTotalCurrent() {
      return (this.byovizCurrent + this.lucentisCurrent + this.biosimilarCurrent);
    },
    graphByovizFuture() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdByovizYearOneFuture + this.$store.getters.newPatientsCurrentCostDmeByovizYearOneFuture + this.$store.getters.newPatientsCurrentCostPdrByovizYearOneFuture + this.$store.getters.newPatientsCurrentCostRvoByovizYearOneFuture + this.$store.getters.newPatientsCurrentCostCnvByovizYearOneFuture);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearTwoFuture + this.$store.getters.newPatientsCurrentCostDmeByovizYearTwoFuture + this.$store.getters.newPatientsCurrentCostPdrByovizYearTwoFuture + this.$store.getters.newPatientsCurrentCostRvoByovizYearTwoFuture + this.$store.getters.newPatientsCurrentCostCnvByovizYearTwoFuture);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdByovizYearThreeFuture + this.$store.getters.newPatientsCurrentCostDmeByovizYearThreeFuture + this.$store.getters.newPatientsCurrentCostPdrByovizYearThreeFuture + this.$store.getters.newPatientsCurrentCostRvoByovizYearThreeFuture + this.$store.getters.newPatientsCurrentCostCnvByovizYearThreeFuture);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    graphLucentisFuture() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearOneFuture + this.$store.getters.newPatientsCurrentCostDmeLucentisYearOneFuture + this.$store.getters.newPatientsCurrentCostPdrLucentisYearOneFuture + this.$store.getters.newPatientsCurrentCostRvoLucentisYearOneFuture + this.$store.getters.newPatientsCurrentCostCnvLucentisYearOneFuture);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearTwoFuture + this.$store.getters.newPatientsCurrentCostDmeLucentisYearTwoFuture + this.$store.getters.newPatientsCurrentCostPdrLucentisYearTwoFuture + this.$store.getters.newPatientsCurrentCostRvoLucentisYearTwoFuture + this.$store.getters.newPatientsCurrentCostCnvLucentisYearTwoFuture);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdLucentisYearThreeFuture + this.$store.getters.newPatientsCurrentCostDmeLucentisYearThreeFuture + this.$store.getters.newPatientsCurrentCostPdrLucentisYearThreeFuture + this.$store.getters.newPatientsCurrentCostRvoLucentisYearThreeFuture + this.$store.getters.newPatientsCurrentCostCnvLucentisYearThreeFuture);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    graphBiosimilarFuture() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearOneFuture + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearOneFuture + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearOneFuture + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearOneFuture + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearOneFuture);
      const yearTwoNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearTwoFuture + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearTwoFuture + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearTwoFuture + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearTwoFuture + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearTwoFuture);
      const yearTwo = (yearOne + yearTwoNew);
      const yearThreeNew = (this.$store.getters.newPatientsCurrentCostWamdBiosimilarYearThreeFuture + this.$store.getters.newPatientsCurrentCostDmeBiosimilarYearThreeFuture + this.$store.getters.newPatientsCurrentCostPdrBiosimilarYearThreeFuture + this.$store.getters.newPatientsCurrentCostRvoBiosimilarYearThreeFuture + this.$store.getters.newPatientsCurrentCostCnvBiosimilarYearThreeFuture);
      const yearThree = (yearOne + yearTwoNew + yearThreeNew);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    graphTotalFuture() {
      return (this.byovizFuture + this.lucentisFuture + this.biosimilarFuture);
    },
    graphDifference() {
      return (this.totalCurrent - this.totalFuture);
    },
    lucentisWamdSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizWamdSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarWamdSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisDmeSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizDmeSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarDmeSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisPdrSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizPdrSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarPdrSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisRvoSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizRvoSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarRvoSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCnvSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizCnvSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvByovizYearThree;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCnvSwitch() {
      const years = this.$store.state.timeframe;
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne;
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalWamdSwitch() {
      return (this.lucentisWamdSwitch + this.byovizWamdSwitch + this.biosimilarWamdSwitch);
    },
    totalDmeSwitch() {
      return (this.lucentisDmeSwitch + this.byovizDmeSwitch + this.biosimilarDmeSwitch);
    },
    totalPdrSwitch() {
      return (this.lucentisPdrSwitch + this.byovizPdrSwitch + this.biosimilarPdrSwitch);
    },
    totalRvoSwitch() {
      return (this.lucentisRvoSwitch + this.byovizRvoSwitch + this.biosimilarRvoSwitch);
    },
    totalCnvSwitch() {
      return (this.lucentisCnvSwitch + this.byovizCnvSwitch + this.biosimilarCnvSwitch);
    },
    totalCurrentSituationSwitch() {
      return (this.totalWamdSwitch + this.totalDmeSwitch + this.totalPdrSwitch + this.totalRvoSwitch + this.totalCnvSwitch);
    },
    lucentisWamdFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisWamd;
    },
    byovizWamdFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostWamdByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizWamd;
    },
    biosimilarWamdFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarWamd;
    },
    lucentisDmeFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisDme;
    },
    byovizDmeFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostDmeByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizDme;
    },
    biosimilarDmeFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarDme;
    },
    lucentisPdrFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisPdr;
    },
    byovizPdrFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostPdrByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizPdr;
    },
    biosimilarPdrFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarPdr;
    },
    lucentisRvoFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisRvo;
    },
    byovizRvoFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostRvoByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizRvo;
    },
    biosimilarRvoFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarRvo;
    },
    lucentisCnvFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisCnv;
    },
    byovizCnvFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostCnvByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizCnv;
    },
    biosimilarCnvFutureSwitch() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarCnv;
    },
    totalWamdFutureSwitch() {
      return (this.lucentisWamdFutureSwitch + this.byovizWamdFutureSwitch + this.biosimilarWamdFutureSwitch);
    },
    totalDmeFutureSwitch() {
      return (this.lucentisDmeFutureSwitch + this.byovizDmeFutureSwitch + this.biosimilarDmeFutureSwitch);
    },
    totalPdrFutureSwitch() {
      return (this.lucentisPdrFutureSwitch + this.byovizPdrFutureSwitch + this.biosimilarPdrFutureSwitch);
    },
    totalRvoFutureSwitch() {
      return (this.lucentisRvoFutureSwitch + this.byovizRvoFutureSwitch + this.biosimilarRvoFutureSwitch);
    },
    totalCnvFutureSwitch() {
      return (this.lucentisCnvFutureSwitch + this.byovizCnvFutureSwitch + this.biosimilarCnvFutureSwitch);
    },
    totalFutureSituationSwitch() {
      return (this.totalWamdFutureSwitch + this.totalDmeFutureSwitch + this.totalPdrFutureSwitch + this.totalRvoFutureSwitch + this.totalCnvFutureSwitch);
    },
    totalDifferenceSwitch() {
      const difference = (this.totalCurrentSituationSwitch - this.totalFutureSituationSwitch);
      return difference;
    },
    byovizCurrentSwitchGraph() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearOne + this.$store.getters.switchPatientsCurrentCostDmeByovizYearOne + this.$store.getters.switchPatientsCurrentCostPdrByovizYearOne + this.$store.getters.switchPatientsCurrentCostRvoByovizYearOne + this.$store.getters.switchPatientsCurrentCostCnvByovizYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearTwo + this.$store.getters.switchPatientsCurrentCostDmeByovizYearTwo + this.$store.getters.switchPatientsCurrentCostPdrByovizYearTwo + this.$store.getters.switchPatientsCurrentCostRvoByovizYearTwo + this.$store.getters.switchPatientsCurrentCostCnvByovizYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdByovizYearThree + this.$store.getters.switchPatientsCurrentCostDmeByovizYearThree + this.$store.getters.switchPatientsCurrentCostPdrByovizYearThree + this.$store.getters.switchPatientsCurrentCostRvoByovizYearThree + this.$store.getters.switchPatientsCurrentCostCnvByovizYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCurrentSwitchGraph() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOne + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOne + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOne + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOne + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearTwo + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdLucentisYearThree + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearThree + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearThree + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearThree + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCurrentSwitchGraph() {
      const years = this.$store.state.timeframe;
      const yearOne = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne);
      const yearTwo = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearTwo + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearTwo);
      const yearThree = (this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearThree + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearThree);
      let total = yearOne;
      if (years === 3) total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalCurrentSwitchGraph() {
      return (this.byovizCurrent + this.lucentisCurrent + this.biosimilarCurrent);
    },
    byovizFutureSwitchGraph() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoByovizYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvByovizYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureByovizWamd
        + this.$store.getters.switchPatientsThreeYearFutureByovizDme
        + this.$store.getters.switchPatientsThreeYearFutureByovizPdr
        + this.$store.getters.switchPatientsThreeYearFutureByovizRvo
        + this.$store.getters.switchPatientsThreeYearFutureByovizCnv
      );
    },
    lucentisFutureSwitchGraph() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureLucentisWamd
        + this.$store.getters.switchPatientsThreeYearFutureLucentisDme
        + this.$store.getters.switchPatientsThreeYearFutureLucentisPdr
        + this.$store.getters.switchPatientsThreeYearFutureLucentisRvo
        + this.$store.getters.switchPatientsThreeYearFutureLucentisCnv
      );
    },
    biosimilarFutureSwitchGraph() {
      if (this.timeframe === 1) {
        return (
          this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOneFuture
          + this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOneFuture
        );
      }
      return (
        this.$store.getters.switchPatientsThreeYearFutureBiosimilarWamd
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarDme
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarPdr
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarRvo
        + this.$store.getters.switchPatientsThreeYearFutureBiosimilarCnv
      );
    },
    totalFutureSwitchGraph() {
      return (this.byovizFuture + this.lucentisFuture + this.biosimilarFuture);
    },
    differenceSwitchGraph() {
      return (this.totalCurrent - this.totalFuture);
    },
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    sendReport() {
      const postData = {};
      postData.email = this.emailAddress;
      postData.newPatients = this.newPatients;
      postData.switchPatients = this.switchPatients;
      postData.allPatients = this.allPatients;
      postData.patientTypes = this.patientTypes;
      postData.drugs = this.drugs;
      postData.timeframe = this.timeframe;
      postData.byovizYearOneShare = this.byovizYearOneShare;
      postData.byovizYearTwoShare = this.byovizYearTwoShare;
      postData.byovizYearThreeShare = this.byovizYearThreeShare;
      postData.lucentisYearOneShare = this.lucentisYearOneShare;
      postData.lucentisYearTwoShare = this.lucentisYearTwoShare;
      postData.lucentisYearThreeShare = this.lucentisYearThreeShare;
      postData.biosimilarYearOneShare = this.biosimilarYearOneShare;
      postData.biosimilarYearTwoShare = this.biosimilarYearTwoShare;
      postData.biosimilarYearThreeShare = this.biosimilarYearThreeShare;
      postData.byovizYearOneFutureShare = this.byovizYearOneFutureShare;
      postData.byovizYearTwoFutureShare = this.byovizYearTwoFutureShare;
      postData.byovizYearThreeFutureShare = this.byovizYearThreeFutureShare;
      postData.lucentisYearOneFutureShare = this.lucentisYearOneFutureShare;
      postData.lucentisYearTwoFutureShare = this.lucentisYearTwoFutureShare;
      postData.lucentisYearThreeFutureShare = this.lucentisYearThreeFutureShare;
      postData.biosimilarYearOneFutureShare = this.biosimilarYearOneFutureShare;
      postData.biosimilarYearTwoFutureShare = this.biosimilarYearTwoFutureShare;
      postData.biosimilarYearThreeFutureShare = this.biosimilarYearThreeFutureShare;
      postData.byovizYearOneShareSwitch = this.byovizYearOneShareSwitch;
      postData.byovizYearTwoShareSwitch = this.byovizYearTwoShareSwitch;
      postData.byovizYearThreeShareSwitch = this.byovizYearThreeShareSwitch;
      postData.lucentisYearOneShareSwitch = this.lucentisYearOneShareSwitch;
      postData.lucentisYearTwoShareSwitch = this.lucentisYearTwoShareSwitch;
      postData.lucentisYearThreeShareSwitch = this.lucentisYearThreeShareSwitch;
      postData.biosimilarYearOneShareSwitch = this.biosimilarYearOneShareSwitch;
      postData.biosimilarYearTwoShareSwitch = this.biosimilarYearTwoShareSwitch;
      postData.biosimilarYearThreeShareSwitch = this.biosimilarYearThreeShareSwitch;
      postData.byovizYearOneFutureShareSwitch = this.byovizYearOneFutureShareSwitch;
      postData.byovizYearTwoFutureShareSwitch = this.byovizYearTwoFutureShareSwitch;
      postData.byovizYearThreeFutureShareSwitch = this.byovizYearThreeFutureShareSwitch;
      postData.lucentisYearOneFutureShareSwitch = this.lucentisYearOneFutureShareSwitch;
      postData.lucentisYearTwoFutureShareSwitch = this.lucentisYearTwoFutureShareSwitch;
      postData.lucentisYearThreeFutureShareSwitch = this.lucentisYearThreeFutureShareSwitch;
      postData.biosimilarYearOneFutureShareSwitch = this.biosimilarYearOneFutureShareSwitch;
      postData.biosimilarYearTwoFutureShareSwitch = this.biosimilarYearTwoFutureShareSwitch;
      postData.biosimilarYearThreeFutureShareSwitch = this.biosimilarYearThreeFutureShareSwitch;
      postData.lucentisWamd = this.lucentisWamd;
      postData.byovizWamd = this.byovizWamd;
      postData.biosimilarWamd = this.biosimilarWamd;
      postData.lucentisDme = this.lucentisDme;
      postData.byovizDme = this.byovizDme;
      postData.biosimilarDme = this.biosimilarDme;
      postData.lucentisPdr = this.lucentisPdr;
      postData.byovizPdr = this.byovizPdr;
      postData.biosimilarPdr = this.biosimilarPdr;
      postData.lucentisRvo = this.lucentisRvo;
      postData.byovizRvo = this.byovizRvo;
      postData.biosimilarRvo = this.biosimilarRvo;
      postData.lucentisCnv = this.lucentisCnv;
      postData.byovizCnv = this.byovizCnv;
      postData.biosimilarCnv = this.biosimilarCnv;
      postData.totalWamd = this.totalWamd;
      postData.totalDme = this.totalDme;
      postData.totalPdr = this.totalPdr;
      postData.totalRvo = this.totalRvo;
      postData.totalCnv = this.totalCnv;
      postData.totalCurrentSituation = this.totalCurrentSituation;
      postData.lucentisWamdFuture = this.lucentisWamdFuture;
      postData.byovizWamdFuture = this.byovizWamdFuture;
      postData.biosimilarWamdFuture = this.biosimilarWamdFuture;
      postData.lucentisDmeFuture = this.lucentisDmeFuture;
      postData.byovizDmeFuture = this.byovizDmeFuture;
      postData.biosimilarDmeFuture = this.biosimilarDmeFuture;
      postData.lucentisPdrFuture = this.lucentisPdrFuture;
      postData.byovizPdrFuture = this.byovizPdrFuture;
      postData.biosimilarPdrFuture = this.biosimilarPdrFuture;
      postData.lucentisRvoFuture = this.lucentisRvoFuture;
      postData.byovizRvoFuture = this.byovizRvoFuture;
      postData.biosimilarRvoFuture = this.biosimilarRvoFuture;
      postData.lucentisCnvFuture = this.lucentisCnvFuture;
      postData.byovizCnvFuture = this.byovizCnvFuture;
      postData.biosimilarCnvFuture = this.biosimilarCnvFuture;
      postData.totalWamdFuture = this.totalWamdFuture;
      postData.totalDmeFuture = this.totalDmeFuture;
      postData.totalPdrFuture = this.totalPdrFuture;
      postData.totalRvoFuture = this.totalRvoFuture;
      postData.totalCnvFuture = this.totalCnvFuture;
      postData.totalFutureSituation = this.totalFutureSituation;
      postData.totalDifference = this.totalDifference;
      postData.graphByovizCurrent = this.graphByovizCurrent;
      postData.graphLucentisCurrent = this.graphLucentisCurrent;
      postData.graphBiosimilarCurrent = this.graphBiosimilarCurrent;
      postData.graphTotalCurrent = this.graphTotalCurrent;
      postData.graphByovizFuture = this.graphByovizFuture;
      postData.graphLucentisFuture = this.graphLucentisFuture;
      postData.graphBiosimilarFuture = this.graphBiosimilarFuture;
      postData.graphTotalFuture = this.graphTotalFuture;
      postData.graphDifference = this.graphDifference;
      postData.lucentisWamdSwitch = this.lucentisWamdSwitch;
      postData.byovizWamdSwitch = this.byovizWamdSwitch;
      postData.biosimilarWamdSwitch = this.biosimilarWamdSwitch;
      postData.lucentisDmeSwitch = this.lucentisDmeSwitch;
      postData.byovizDmeSwitch = this.byovizDmeSwitch;
      postData.biosimilarDmeSwitch = this.biosimilarDmeSwitch;
      postData.lucentisPdrSwitch = this.lucentisPdrSwitch;
      postData.byovizPdrSwitch = this.byovizPdrSwitch;
      postData.biosimilarPdrSwitch = this.biosimilarPdrSwitch;
      postData.lucentisRvoSwitch = this.lucentisRvoSwitch;
      postData.byovizRvoSwitch = this.byovizRvoSwitch;
      postData.biosimilarRvoSwitch = this.biosimilarRvoSwitch;
      postData.lucentisCnvSwitch = this.lucentisCnvSwitch;
      postData.byovizCnvSwitch = this.byovizCnvSwitch;
      postData.biosimilarCnvSwitch = this.biosimilarCnvSwitch;
      postData.totalWamdSwitch = this.totalWamdSwitch;
      postData.totalDmeSwitch = this.totalDmeSwitch;
      postData.totalPdrSwitch = this.totalPdrSwitch;
      postData.totalRvoSwitch = this.totalRvoSwitch;
      postData.totalCnvSwitch = this.totalCnvSwitch;
      postData.totalCurrentSituationSwitch = this.totalCurrentSituationSwitch;
      postData.lucentisWamdFutureSwitch = this.lucentisWamdFutureSwitch;
      postData.byovizWamdFutureSwitch = this.byovizWamdFutureSwitch;
      postData.biosimilarWamdFutureSwitch = this.biosimilarWamdFutureSwitch;
      postData.lucentisDmeFutureSwitch = this.lucentisDmeFutureSwitch;
      postData.byovizDmeFutureSwitch = this.byovizDmeFutureSwitch;
      postData.biosimilarDmeFutureSwitch = this.biosimilarDmeFutureSwitch;
      postData.lucentisPdrFutureSwitch = this.lucentisPdrFutureSwitch;
      postData.byovizPdrFutureSwitch = this.byovizPdrFutureSwitch;
      postData.biosimilarPdrFutureSwitch = this.biosimilarPdrFutureSwitch;
      postData.lucentisRvoFutureSwitch = this.lucentisRvoFutureSwitch;
      postData.byovizRvoFutureSwitch = this.byovizRvoFutureSwitch;
      postData.biosimilarRvoFutureSwitch = this.biosimilarRvoFutureSwitch;
      postData.lucentisCnvFutureSwitch = this.lucentisCnvFutureSwitch;
      postData.byovizCnvFutureSwitch = this.byovizCnvFutureSwitch;
      postData.biosimilarCnvFutureSwitch = this.biosimilarCnvFutureSwitch;
      postData.totalWamdFutureSwitch = this.totalWamdFutureSwitch;
      postData.totalDmeFutureSwitch = this.totalDmeFutureSwitch;
      postData.totalPdrFutureSwitch = this.totalPdrFutureSwitch;
      postData.totalRvoFutureSwitch = this.totalRvoFutureSwitch;
      postData.totalCnvFutureSwitch = this.totalCnvFutureSwitch;
      postData.totalFutureSituationSwitch = this.totalFutureSituationSwitch;
      postData.totalDifferenceSwitch = this.totalDifferenceSwitch;
      postData.byovizCurrentSwitchGraph = this.byovizCurrentSwitchGraph;
      postData.lucentisCurrentSwitchGraph = this.lucentisCurrentSwitchGraph;
      postData.biosimilarCurrentSwitchGraph = this.biosimilarCurrentSwitchGraph;
      postData.totalCurrentSwitchGraph = this.totalCurrentSwitchGraph;
      postData.byovizFutureSwitchGraph = this.byovizFutureSwitchGraph;
      postData.lucentisFutureSwitchGraph = this.lucentisFutureSwitchGraph;
      postData.biosimilarFutureSwitchGraph = this.biosimilarFutureSwitchGraph;
      postData.totalFutureSwitchGraph = this.totalFutureSwitchGraph;
      postData.differenceSwitchGraph = this.differenceSwitchGraph;
      postData.reportTitle = this.reportTitle;

      axios.post('/reports/report', postData)
        .then(() => {
          this.$emit('close');
        });
    },
  },
};
</script>
